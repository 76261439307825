import { CSSObject } from '@emotion/core';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { ReactNode, useState } from 'react';
import Media from 'react-media';
import SwiperCore, {
    A11y,
    Autoplay,
    EffectCoverflow,
    Lazy,
    Mousewheel,
    Navigation,
    Pagination,
    Scrollbar,
    Swiper,
    SwiperOptions,
    Thumbs,
} from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

import LoadingSkeleton from '@controls/LoadingSkeleton';

import { Button, colors, scale, typography, useTheme } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import ArrowLeftIcon from '@icons/24/arrow-left.svg';
import ArrowRightIcon from '@icons/24/arrow-right.svg';
import BigArrowLeftIcon from '@icons/big-arrow-left.svg';
import BigArrowRightIcon from '@icons/big-arrow-right.svg';

import useSwiperRef from './useSwiperRef';

const ThumbsBlock = dynamic(() => import('./ThumbsBlock'));
const ThumbsPopupBlock = dynamic(() => import('./ThumbsPopupBlock'));

SwiperCore.use([Pagination, Navigation, A11y, Autoplay, Lazy, Mousewheel, Scrollbar, EffectCoverflow, Thumbs]);

export interface CarouselProps extends SwiperOptions {
    spaceBetween?: number;
    slidesPerView?: number | 'auto';
    isLoading?: boolean;
    /** Children */
    children: ReactNode;
    /** Enable navigation */
    navigation?: boolean;
    /** Enable pagination */
    pagination?: boolean;
    /** Enable scrollbar */
    scrollbar?: boolean;
    /** Enable lazy loading */
    lazy?: boolean;
    /** Enable autoplay */
    autoplay?: boolean;
    itemsNumber?: number | null;
    activeIndex?: number;
    setActiveIndex?: (index: number) => void;
    /** Carousel type  */
    type?:
        | 'default'
        | 'thumbs'
        | 'thumbsPopup'
        | 'productCard'
        | 'products'
        | 'images'
        | 'options'
        | 'category'
        | 'categories'
        | 'main-banners';
    /** Enable nav on mobile */
    mobileNav?: boolean;
    /** Showing buttons on hover (only type productDetail) */
    isHoverEffectBtns?: boolean;
    /** Passing the handler to the slider */
    handleClick?: () => void;
    thumbsItems?: any[];
    /** Title of slider */
    title?: string;
    /** Link to category */
    link?: string;
}

const Carousel = ({
    isLoading,
    spaceBetween,
    slidesPerView,
    children,
    navigation,
    pagination,
    scrollbar,
    lazy,
    autoplay,
    type = 'default',
    mobileNav,
    isHoverEffectBtns,
    handleClick,
    activeIndex = 0,
    setActiveIndex,
    itemsNumber,
    thumbsItems,
    title,
    link,

    ...props
}: CarouselProps) => {
    const { xs, sm, md, lg } = useMedia();
    const { layout, shadows } = useTheme();
    const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();
    const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
    const [paginationEl, paginationRef] = useSwiperRef<HTMLDivElement>();
    const [scrollbarEl, scrollbarRef] = useSwiperRef<HTMLDivElement>();
    const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null>(null);

    const rootStyle: CSSObject = {
        height: '100%',
        width: '100%',
        '.swiper-slide': {
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
            width: 'fit-content',
            alignItems: 'center',

            ...(type === 'thumbs' && {
                height: scale(55),
                [lg]: {
                    maxHeight: scale(42),
                },
            }),
            ...(type === 'thumbsPopup' && {
                height: scale(70),
                [md]: {
                    maxHeight: 'none',
                    height: scale(60),
                },
            }),
            ...(type === 'main-banners' && {
                width: '100%',
            }),
        },

        ...(type === 'thumbs' && {
            width: '528px',
            display: 'flex',
            gap: scale(2),
            justifyContent: 'space-between',
            [md]: {
                flexDirection: 'column',
            },
        }),
        ...(type === 'thumbsPopup' && {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            [md]: {
                flexDirection: 'column',
            },
            [xs]: {
                justifyContent: 'space-between',
            },
        }),
    };

    const containerStyle: CSSObject = {
        '> div': {
            display: 'flex',
        },

        height: '100%',
        ...(type === 'thumbs' && {
            width: scale(55),
            maxHeight: scale(55),
            [lg]: {
                width: scale(42),
                maxHeight: scale(42),
            },
            [md]: {
                width: '100%',
            },
        }),
        ...(type === 'thumbsPopup' && {
            width: '78%',
            [md]: {
                width: '100%',
            },
        }),
        ...((type === 'products' || type === 'category') && {
            padding: `${scale(1)}px 0`,
        }),
    };

    const buttonStyle: CSSObject = {
        position: 'absolute',
        top: '50%',
        zIndex: 10,

        ...(type === 'thumbs' && {
            top: 'auto',
            bottom: scale(20),
            border: 'none !important',
            boxShadow: shadows?.small,
            height: `${scale(6)}px !important`,
            width: `${scale(6)}px !important`,
        }),

        ...((type === 'products' || type === 'categories') && {
            top: -scale(9),
            width: scale(5),
            height: scale(5),
            border: `1px solid ${colors.textNuanceLight}`,
            borderRadius: '50% !important',

            ':disabled': {
                opacity: 0.5,
            },
        }),
    };

    const paginationStyle: CSSObject = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: type === 'productCard' ? scale(1, true) : scale(2),
        '.swiper-pagination-bullet': {
            display: (itemsNumber && itemsNumber > 1) || itemsNumber === undefined ? 'flex' : 'none',
            alignItems: 'center',
            width: scale(3),
            height: scale(1),
            cursor: 'pointer',
            transition: 'width 200ms ease',

            '> div': {
                backgroundColor: colors.textNuanceLight,
                height: 2,
                width: scale(3),
            },

            '+ .swiper-pagination-bullet': {
                marginLeft: 2,
            },
        },
        '.swiper-pagination-bullet-active > div': {
            backgroundColor: colors.primary,
        },
    };

    const scrollbarStyle: CSSObject = {
        marginTop: scale(2),
        height: scale(1),
        '.swiper-scrollbar-drag': {
            backgroundColor: colors?.black,
            opacity: '0.1',
            height: '100%',
            width: '100%',
            borderRadius: scale(1, true),
        },
    };

    const swiperProps = {
        a11y: {
            prevSlideMessage: 'Предыдущий слайд',
            nextSlideMessage: 'Следующий слайд',
            firstSlideMessage: 'Первый слайд',
            lastSlideMessage: 'Последний слайд',
            paginationBulletMessage: 'Перейти к слайду {{index}}',
        },
        initialSlide: activeIndex,
        watchOverflow: true,
        mousewheel: false,
        ...(spaceBetween && { spaceBetween }),
        ...(slidesPerView && { slidesPerView }),
    };

    const thumbsSwiperProps: any = {
        thumbs: { swiper: thumbsSwiper },
    };

    const params = {
        modules: [Navigation, Pagination, A11y, Autoplay, Lazy, Thumbs],
        ...swiperProps,
        ...(type === 'images' && {
            slidesPerView: 'auto',
            spaceBetween: scale(3, true),
        }),
        ...(type === 'products' && {
            slidesPerView: 'auto',
            spaceBetween: scale(3),
            breakpoints: {
                [layout?.breakpoints?.xxxs || 320]: { spaceBetween: scale(2) },
                [layout?.breakpoints?.sm || 768]: { spaceBetween: scale(3, true) },
                [layout?.breakpoints?.lg || 1280]: { spaceBetween: scale(3) },
            },
        }),
        ...((type === 'thumbs' && thumbsSwiperProps) || (type === 'thumbsPopup' && thumbsSwiperProps)),
        ...(type === 'category' && {
            spaceBetween: scale(3),
            breakpoints: {
                [layout?.breakpoints?.xxxs || 320]: { spaceBetween: scale(1), slidesPerView: 'auto' },
                [layout?.breakpoints?.sm || 768]: { spaceBetween: scale(3, true), slidesPerView: 'auto' },
                [layout?.breakpoints?.lg || 1280]: { spaceBetween: scale(3), slidesPerView: slidesPerView || 3 },
            },
        }),
        ...(type === 'categories' && {
            breakpoints: {
                [layout?.breakpoints?.xxxs || 320]: { slidesPerView: 'auto' },
                [layout?.breakpoints?.lg || 1280]: { slidesPerView: 6 },
            },
        }),
        ...(type === 'options' && {
            breakpoints: {
                [layout?.breakpoints?.xxxs || 320]: { spaceBetween: scale(1), slidesPerView: 'auto' },
                [layout?.breakpoints?.sm || 768]: { spaceBetween: scale(2), slidesPerView: 'auto' },
                [layout?.breakpoints?.lg || 1280]: { spaceBetween: scale(3), slidesPerView: 4 },
            },
        }),
    };

    const thumbsParams: any = {
        modules: [Thumbs],
        slideToClickedSlide: true,
        centeredSlides: false,
        slidesPerView: 'auto',
        spaceBetween: 6,
        direction: 'vertical',
        onSwiper: setThumbsSwiper,
        breakpoints: {
            [layout?.breakpoints?.xxxs || 320]: { direction: 'horizontal' },
            [layout?.breakpoints?.md || 1024]: { direction: 'vertical' },
        },
    };

    if (navigation) {
        params.navigation = {
            nextEl,
            prevEl,
        };
    }

    if (pagination) {
        params.pagination = {
            el: paginationEl,
            clickable: true,
            renderBullet: (_: number, className: string) => `<div class="${className}"><div></div></div>`,
        };
    }

    if (scrollbar) {
        params.scrollbar = {
            el: scrollbarEl,
            draggable: true,
        };
    }

    if (lazy) {
        params.lazy = {
            loadPrevNext: true,
            loadOnTransitionStart: true,
        };
        params.preloadImages = false;
    }

    if (autoplay) {
        params.autoplay = {
            delay: 5000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        };
    }

    return (
        <div css={rootStyle}>
            {(type === 'products' || type === 'categories') && (
                <div
                    css={{
                        marginBottom: scale(4),
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: scale(5),
                        alignItems: 'center',
                    }}
                >
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                        {title && <h2 css={typography('h3')}>{title}</h2>}
                        {link && (
                            <Link legacyBehavior href={link} passHref prefetch={false}>
                                <a
                                    aria-label={'Смотреть все'}
                                    css={{
                                        marginLeft: scale(2),
                                        textDecorationLine: 'underline',
                                        color: colors.textNuanceLight,
                                        ':hover': { color: colors.primary },
                                        transition: 'color ease 200ms',
                                        [sm]: { display: 'none' },
                                    }}
                                >
                                    Смотреть все
                                </a>
                            </Link>
                        )}
                    </div>
                </div>
            )}
            {type === 'thumbs' && (
                <div css={{ position: 'absolute' }}>
                    <ThumbsBlock
                        itemsNumber={itemsNumber}
                        thumbsItems={thumbsItems}
                        isLoading={isLoading}
                        thumbsParams={thumbsParams}
                    />
                </div>
            )}
            {type === 'thumbsPopup' && (
                <ThumbsPopupBlock thumbsItems={thumbsItems} isLoading={isLoading} thumbsParams={thumbsParams}>
                    {children}
                </ThumbsPopupBlock>
            )}

            <div
                css={{
                    position: 'relative',
                    ...(type === 'thumbsPopup' && {
                        margin: '0 auto',
                        width: 'calc(100% - 132px)',
                        [md]: { order: 1 },
                        [xs]: { margin: 'auto 0' },
                    }),
                    ...(type === 'thumbs' && { [md]: { order: 1 } }),
                    '&:hover button ': { opacity: isHoverEffectBtns ? 1 : '' },
                }}
            >
                <SwiperComponent
                    {...params}
                    {...props}
                    css={containerStyle}
                    onSlideChangeTransitionEnd={e => {
                        if (setActiveIndex) setActiveIndex(e.activeIndex);
                    }}
                >
                    {!isLoading ? (
                        children
                    ) : (
                        <SwiperSlide style={{ alignItems: 'stretch' }}>
                            <div style={{ flexGrow: 1 }}>
                                <LoadingSkeleton height="100%" css={{ lineHeight: 'inherit' }} />
                            </div>
                        </SwiperSlide>
                    )}
                </SwiperComponent>
                {!isLoading && navigation && (
                    <Media query={{ minWidth: layout?.breakpoints.sm || 768 }} defaultMatches>
                        {matches =>
                            matches || mobileNav || type === 'thumbsPopup' ? (
                                <>
                                    <Button
                                        data-testid="button-previous"
                                        Icon={
                                            type === 'products' || type === 'categories'
                                                ? ArrowLeftIcon
                                                : BigArrowLeftIcon
                                        }
                                        ref={prevElRef}
                                        css={{
                                            ...buttonStyle,
                                            left: 0,
                                            transform: 'translate(-50%, -50%)',

                                            ...(!matches && {
                                                left: scale(1),
                                            }),

                                            ...(type === 'thumbs' && {
                                                transform: 'none',
                                                left: scale(3),
                                            }),
                                            ...(type === 'thumbsPopup' && {
                                                left: '6%',
                                                opacity: isHoverEffectBtns ? 0 : 1,
                                                '> svg': {
                                                    width: scale(6),
                                                    height: scale(11),

                                                    [md]: {
                                                        width: scale(3),
                                                        height: scale(3),
                                                    },
                                                },
                                                [md]: {
                                                    left: '5%',
                                                },
                                            }),
                                            ...((type === 'products' || type === 'categories') && {
                                                right: scale(1, false, 46),
                                                left: 'initial',
                                                transform: 'none',
                                            }),
                                        }}
                                        hidden
                                        size="navigation"
                                        theme="gray"
                                    >
                                        Предыдущий слайд
                                    </Button>
                                    <Button
                                        data-testid="button-next"
                                        Icon={
                                            type === 'products' || type === 'categories'
                                                ? ArrowRightIcon
                                                : BigArrowRightIcon
                                        }
                                        ref={nextElRef}
                                        css={{
                                            ...buttonStyle,
                                            right: 0,
                                            transform: 'translate(50%, -50%)',

                                            ...(!matches && {
                                                right: scale(1),
                                            }),

                                            ...(type === 'thumbs' && {
                                                transform: 'none',
                                                right: scale(3),
                                            }),

                                            ...(type === 'thumbsPopup' && {
                                                right: '6%',
                                                opacity: isHoverEffectBtns ? 0 : 1,
                                                '> svg': {
                                                    width: scale(6),
                                                    height: scale(11),

                                                    [md]: {
                                                        width: scale(3),
                                                        height: scale(3),
                                                    },
                                                },
                                                [md]: {
                                                    right: '5%',
                                                },
                                            }),
                                            ...((type === 'products' || type === 'categories') && {
                                                right: 0,
                                                transform: 'none',
                                            }),
                                        }}
                                        hidden
                                        size="navigation"
                                        theme="gray"
                                    >
                                        Следующий слайд
                                    </Button>
                                </>
                            ) : null
                        }
                    </Media>
                )}
            </div>

            <Media query={{ maxWidth: layout?.breakpoints.sm || 768 }} defaultMatches={false}>
                {matches =>
                    matches && (type === 'products' || type === 'category') && link ? (
                        <div css={{ display: 'flex', justifyContent: 'center' }}>
                            <Link legacyBehavior href={link} passHref prefetch={false}>
                                <a
                                    aria-label={'Смотреть все'}
                                    css={{
                                        width: scale(28),
                                        height: scale(6),
                                        backgroundColor: colors.backgroundMain,
                                        borderRadius: scale(1),
                                        ...typography('bodySmMedium'),
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        [xs]: {
                                            width: '100%',
                                        },
                                    }}
                                >
                                    Смотреть все
                                </a>
                            </Link>
                        </div>
                    ) : null
                }
            </Media>

            {!isLoading && pagination && (
                <div ref={paginationRef} css={paginationStyle} onClick={e => e.stopPropagation()} />
            )}
            {!isLoading && scrollbar && (
                <Media query={{ maxWidth: layout?.breakpoints.sm || 768 }} defaultMatches>
                    {matches => matches && <div ref={scrollbarRef} css={scrollbarStyle} />}
                </Media>
            )}
        </div>
    );
};

Carousel.Item = SwiperSlide;

export default Carousel;
